import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';

function usePageViews() {
    const location = useLocation();
    const isConsent = localStorage.getItem('cookiesAcceptance');

    useEffect(() => {
        if (isConsent) {
            initiateTracking();
        }
    }, []);

    useEffect(() => {
        if (isConsent) {
            sendPageView();
        }
    }, [location]);

    const options = {
        gaOptions: {
            titleCase: false,
        },
    };

    const initiateTracking = () => {
        ReactGA.initialize('UA-77111023-5', options);
        ReactPixel.init('581218512920157');
    };

    const initiateTrackingWithPageView = () => {
        initiateTracking();
        sendPageView();
    };

    const sendPageView = () => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
        ReactPixel.pageView();
    };

    const sendEvent = (eventName: string) => {
        ReactGA.event({
            category: 'General',
            action: eventName,
        });
        ReactPixel.trackCustom(eventName, '');
    };

    return { initiateTrackingWithPageView, sendEvent };
}

export default usePageViews;
