import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { routes } from '../../../utils/routes';
import styles from './Header.module.scss';
import KubeCoinLogo from '../../../assets/images/kubecoin-logo-full.svg';
import closeIcon from '../../../assets/icons/close-white.svg';
import menuIcon from '../../../assets/icons/menu-white.svg';
import { menuLinksHeader } from '../../../utils/linkData';

const getOffset = (section: String) => {
    if (section === 'welcome') return 80;
    if (section === 'buyKube') return 180;
    if (section === 'usecase') return 60;
    if (section === 'products') return 60;
    if (section === 'marketplace') return 600;
    return 0;
};

const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = getOffset(el.id);
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const Header = () => {
    const mql = window.matchMedia('(min-width: 1150px)');
    const [showMenu, setShowMenu] = useState(false);
    const { pathname } = useLocation();
    const isHomePage = pathname === '/';
    const isTeamPage = pathname === routes.team;
    const isPartnersPage = pathname === routes.partners;
    const isContactPage = pathname === routes.contact;
    const isBackdropFilterHeader = isHomePage || isTeamPage || isPartnersPage || isContactPage;

    useEffect(() => {
        if (showMenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'initial';
        }
        return () => {
            document.body.style.overflow = 'initial';
        };
    }, [showMenu]);

    const onMenuIconClick = () => {
        setShowMenu(!showMenu);
    };

    const closeMenuIfOpen = () => {
        if (showMenu) setShowMenu(false);
    };

    useEffect(() => {
        mql.addEventListener('change', closeMenuIfOpen);

        return () => window.removeEventListener('change', closeMenuIfOpen);
    }, [mql]);

    return (
        <header className={`${styles.headerContainer} ${isBackdropFilterHeader && styles.headerContainerHome}`}>
            <div className={styles.headerWrapper}>
                <Link to={routes.home} className={styles.logoContainer}>
                    <img className={styles.logoImage} src={KubeCoinLogo} alt="Kubecoin logo" />
                </Link>
                <div className={styles.navlinksContainer}>
                    <ul className={`${styles.navLinks} ${showMenu && styles.navLinksActive}`}>
                        {menuLinksHeader.map((link) => (
                            <li key={link.text}>
                                <HashLink
                                    to={link.route}
                                    scroll={(el) => scrollWithOffset(el)}
                                    smooth
                                    className={styles.link}
                                    onClick={() => setShowMenu(false)}
                                    aria-label={`Go to the ${link.text} section of home page`}
                                >
                                    {link.text}
                                </HashLink>
                            </li>
                        ))}

                        <li>
                            <Link
                                to={routes.team}
                                className={styles.link}
                                onClick={() => setShowMenu(false)}
                                aria-label="Navigate to team page"
                            >
                                Team
                            </Link>
                        </li>

                        <li>
                            <Link
                                to={routes.partners}
                                className={styles.link}
                                onClick={() => setShowMenu(false)}
                                aria-label="Navigate to partners page"
                            >
                                Partners
                            </Link>
                        </li>

                        <li>
                            <Link
                                to={routes.contact}
                                className={styles.link}
                                onClick={() => setShowMenu(false)}
                                aria-label="Navigate to contact page"
                            >
                                Contact
                            </Link>
                        </li>

                        <li>
                            <a
                                href="https://cdn.kubecoin.org/media/public/kubecoin_whitepaper.pdf"
                                target="_blank"
                                rel="noreferrer"
                                className={styles.link}
                                onClick={closeMenuIfOpen}
                                aria-label="Open kubecoin whitepaper in a new tab"
                            >
                                Whitepaper
                            </a>
                        </li>

                        <li>
                            <a
                                href="https://kubepay.io"
                                target="_blank"
                                rel="noreferrer"
                                className={`${styles.link} ${styles.kubepayLink}`}
                                onClick={closeMenuIfOpen}
                                aria-label="Open kubepay webpage in a new tab"
                            >
                                KubePay
                            </a>
                        </li>
                    </ul>
                    <button className={styles.menuButton} onClick={onMenuIconClick}>
                        <img src={showMenu ? closeIcon : menuIcon} alt={showMenu ? 'Close menu' : 'Open menu'} />
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
