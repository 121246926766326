import { useState, Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import routes, { pagesWithFooter } from './utils/routes';
import Header from './components/global/header/Header';
import useTracking from './hooks/useTracking';
import useHelmet from './hooks/useHelmet';
import useScrollToTop from './hooks/useScrollToTop';
import Team from './components/pages/team/Team';
import Partners from './components/pages/partners/Partners';

const Home = lazy(() => import('./components/pages/home/Home'));
const Faq = lazy(() => import('./components/pages/faqs/Faqs'));
const PrivacyPolicy = lazy(() => import('./components/pages/privacyPolicy/PrivacyPolicy'));
const Terms = lazy(() => import('./components/pages/terms/Terms'));
const Cookies = lazy(() => import('./components/pages/cookies/Cookies'));
const Footer = lazy(() => import('./components/global/footer/Footer'));
const Contact = lazy(() => import('./components/pages/contact/Contact'));
const CookiesModal = lazy(() => import('./components/global/cookiesModal/CookiesModal'));

function App() {
    const location = useLocation();
    const hasFooter = pagesWithFooter.includes(location.pathname);
    const cookiesLocalStorage = localStorage.getItem('cookiesAcceptance');
    const [showCookies, setShowCookies] = useState<boolean>(cookiesLocalStorage === null);
    const { HelmetContainer } = useHelmet();

    useScrollToTop();
    useTracking();

    return (
        <>
            {showCookies && <CookiesModal setShowCookies={setShowCookies} />}
            <HelmetContainer />
            <Header />
            <Suspense fallback={null}>
                <main>
                    <Routes>
                        <Route path={routes.home} element={<Home />} />
                        <Route path={routes.contact} element={<Contact />} />
                        <Route path={routes.faqs} element={<Faq />} />
                        <Route path={routes.terms} element={<Terms />} />
                        <Route path={routes.policy} element={<PrivacyPolicy />} />
                        <Route path={routes.cookies} element={<Cookies />} />
                        <Route path={routes.team} element={<Team />} />
                        <Route path={routes.partners} element={<Partners />} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </main>

                {hasFooter && <Footer />}
            </Suspense>
        </>
    );
}

export default App;
