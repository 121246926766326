import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import routes from '../utils/routes';

const useHelmet = () => {
    const location = useLocation();

    const getCurrentPathname = () => {
        const pathnameHasFinalSlash = location.pathname.match('/.*/$');
        if (pathnameHasFinalSlash) {
            const pathnameWithoutFinalSlash = location.pathname.replace(/\/+$/, '');
            return pathnameWithoutFinalSlash;
        }
        return location.pathname;
    };

    const currentPathname = getCurrentPathname();

    let title = 'KubeCoin | The leading cryptocurrency for digital payments';
    let description =
        'Pay, send, receive, store and exchange with just one click. We created 7 innovative projects that will integrate digital assets in the real world.';

    if (currentPathname === routes.faqs) {
        title = 'KubeCoin | Frequent Asked Questions';
        description =
            'Everything you need to know about KubeCoin (KUBE). On this page you will find the most asked questions by our users.';
    }

    if (currentPathname === routes.contact) {
        title = 'KubeCoin | Contact';
        description =
            'KubeCoin (KUBE) is part of the Kube Ecosystem. Fill out the form below to contact the team about your question or suggestion.';
    }

    const isRouteToPreventIndexing =
        currentPathname === routes.terms || currentPathname === routes.cookies || currentPathname === routes.policy;

    const HelmetContainer = () => (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {isRouteToPreventIndexing && <meta name="robots" content="noindex" />}
            <link rel="canonical" href={`https://kubecoin.org${currentPathname}`} />
        </Helmet>
    );

    return { HelmetContainer };
};

export default useHelmet;
