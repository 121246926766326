import { routes } from './routes';

export const menuLinksHeader = [
    { text: 'Buy KUBE', route: '/#buyKube' },
    { text: 'Use case', route: '/#usecase' },
    { text: 'Products', route: '/#products' },
    { text: 'Roadmap', route: '/#roadmap' },
];

export const privacyLinks = [
    { text: 'Privacy Policy', route: routes.policy, id: 1 },
    { text: 'Terms & Conditions', route: routes.terms, id: 2 },
    { text: 'Cookies', route: routes.cookies, id: 3 },
    { text: 'FAQs', route: routes.faqs, id: 4 },
];

export const exchangesLinks = [
    { text: 'Huobi', link: 'https://www.huobi.com/en-us/exchange/kube_usdt' },
    { text: 'Gate.io', link: 'https://www.gate.io/trade/kube_usdt' },
    { text: 'Bitmart', link: 'https://www.bitmart.com/trade/en?symbol=KUBE_USDT&layout=basic' },
    { text: 'Mexc', link: 'https://www.mexc.com/exchange/KUBE_USDT' },
    { text: 'Bitrue', link: 'https://www.bitrue.com/trade/kube_usdt' },
    { text: 'Deepcoin', link: 'https://spot.deepcoin.com/en' },
    { text: 'SundaeSwap', link: 'https://exchange.sundaeswap.finance/#/swap' },
    { text: 'Minswap', link: 'https://app.minswap.org/swap' },
    { text: 'Bitrue', link: 'https://www.bitrue.com/trade/kube_usdt' },
];
