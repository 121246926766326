import logoAlldayzip from '../../../assets/images/partners/alldayzip.png';
import logoAnantara from '../../../assets/images/partners/anantara.png';
import logoAndaSeat from '../../../assets/images/partners/anda-seat.png';
import logoBabbel from '../../../assets/images/partners/babbel.png';
import logoBeachScape from '../../../assets/images/partners/beach-scape.png';
import logoBelighSoft from '../../../assets/images/partners/belighsoft.png';
import logoBeliveHotels from '../../../assets/images/partners/belive-hotels.png';
import logoBestBuy from '../../../assets/images/partners/bestbuy.png';
import logoBluetti from '../../../assets/images/partners/bluetti.png';
import logoBuyBestGear from '../../../assets/images/partners/buy-best-gear.png';
import logoChoiceHotel from '../../../assets/images/partners/choice-hotel.png';
import logoCityExpress from '../../../assets/images/partners/city-express.png';
import logoCivitatis from '../../../assets/images/partners/civitatis.png';
import logoCleanEmail from '../../../assets/images/partners/clean-email.png';
import logoCodaShop from '../../../assets/images/partners/codashop.png';
import logoCosmos from '../../../assets/images/partners/cosmos.png';
import logoEncountersTravel from '../../../assets/images/partners/encounters-travel.png';
import logoEtihad from '../../../assets/images/partners/etihad.png';
import logoExcire from '../../../assets/images/partners/excire.png';
import logoGamivo from '../../../assets/images/partners/gamivo.png';
import logoGtpPlayer from '../../../assets/images/partners/gtp-player.png';
import logoH10 from '../../../assets/images/partners/h10.png';
import logoHilton from '../../../assets/images/partners/hilton.png';
import logoIhg from '../../../assets/images/partners/ihg.png';
import logoIScooter from '../../../assets/images/partners/iscooter.png';
import logoLenovo from '../../../assets/images/partners/lenovo.png';
import logoLightInTheBox from '../../../assets/images/partners/light-in-the-box.png';
import logoLopesan from '../../../assets/images/partners/lopesan.png';
import logoMillenium from '../../../assets/images/partners/millenium.png';
import logoMinInTheBox from '../../../assets/images/partners/mini-in-the-box.png';
import logoMissSixty from '../../../assets/images/partners/miss-sixty.png';
import logoNameCheap from '../../../assets/images/partners/namecheap.png';
import logoNhHotels from '../../../assets/images/partners/nh-hotels.png';
import logoOmio from '../../../assets/images/partners/omio.png';
import logoOriginal from '../../../assets/images/partners/original.png';
import logoOstar from '../../../assets/images/partners/ostar.png';
import logoPalace from '../../../assets/images/partners/palace.png';
import logoParallels from '../../../assets/images/partners/parallels.png';
import logoPestana from '../../../assets/images/partners/pestana.png';
import logoSaramonic from '../../../assets/images/partners/saramonic.png';
import logoSsls from '../../../assets/images/partners/ssls.png';
import logoStressFreeCarRental from '../../../assets/images/partners/stress-free-car-rental.png';
import logoTafer from '../../../assets/images/partners/tafer.png';
import logoTextDesign from '../../../assets/images/partners/text-design.png';
import logoTomboyX from '../../../assets/images/partners/tomboy-x.png';
import logoUnitedMileagePlus from '../../../assets/images/partners/united-mileage-plus.png';
import logoUperPerfect from '../../../assets/images/partners/uperperfect.png';
import logoVegas from '../../../assets/images/partners/vegas.png';
import logoVertaGear from '../../../assets/images/partners/vertagear.png';
import logoWander from '../../../assets/images/partners/wander.png';
import logoWorldOfWarships from '../../../assets/images/partners/world-of-warships.png';
import logoZafiro from '../../../assets/images/partners/zafiro.png';

const partners = [
    logoAlldayzip,
    logoAnantara,
    logoAndaSeat,
    logoBabbel,
    logoBeachScape,
    logoBuyBestGear,
    logoBluetti,
    logoBestBuy,
    logoBeliveHotels,
    logoBelighSoft,
    logoChoiceHotel,
    logoCityExpress,
    logoCivitatis,
    logoCleanEmail,
    logoCodaShop,
    logoCosmos,
    logoEncountersTravel,
    logoEtihad,
    logoExcire,
    logoGamivo,
    logoGtpPlayer,
    logoH10,
    logoHilton,
    logoIhg,
    logoIScooter,
    logoLenovo,
    logoLightInTheBox,
    logoLopesan,
    logoMillenium,
    logoMinInTheBox,
    logoMissSixty,
    logoNameCheap,
    logoNhHotels,
    logoOmio,
    logoOriginal,
    logoOstar,
    logoPalace,
    logoParallels,
    logoPestana,
    logoSaramonic,
    logoSsls,
    logoStressFreeCarRental,
    logoTafer,
    logoTextDesign,
    logoTomboyX,
    logoUnitedMileagePlus,
    logoUperPerfect,
    logoVegas,
    logoVertaGear,
    logoWander,
    logoWorldOfWarships,
    logoZafiro,
];

export default partners;
