import { Link } from 'react-router-dom';
import linkedinImg from '../../../../assets/icons/linkedin-icon.svg';
import telegramImg from '../../../../assets/icons/telegram-icon.svg';
import twitterImg from '../../../../assets/icons/twitter-icon.svg';
import styles from './Member.module.scss';

interface IMember {
    name: string;
    position: string;
    linkedin: string;
    telegram: string;
    twitter: string;
    image: string;
}

interface IProps {
    member: IMember;
    reverseInMobile?: boolean;
}

const Member = ({ member, reverseInMobile = false }: IProps) => {
    console.log('Render component');

    return (
        <div className={`${styles.boxContainer} ${reverseInMobile && styles.reverseBox}`}>
            <h2 className={styles.memberTitle}>{member.name}</h2>
            <h3 className={styles.memberPosition}>{member.position}</h3>
            <div className={styles.socialContainer}>
                <a href={member.linkedin} target="_blank" rel="noreferrer">
                    <img src={linkedinImg} className={styles.socialIcon} alt="" />
                </a>
                <a href={member.telegram} target="_blank" rel="noreferrer">
                    <img src={telegramImg} className={styles.socialIcon} alt="" />
                </a>
                <a href={member.twitter} target="_blank" rel="noreferrer">
                    <img src={twitterImg} className={styles.socialIcon} alt="" />
                </a>
            </div>
            <img src={member.image} className={styles.memberImage} alt="" />
        </div>
    );
};

export default Member;
