export const routes = {
    home: '/',
    cookies: '/cookies',
    policy: '/policy',
    contact: '/contact',
    faqs: '/faqs',
    terms: '/terms',
    landing: '/home',
    team: '/team',
    partners: '/partners',
};

export const pagesWithFooter = [
    routes.home,
    routes.faqs,
    routes.policy,
    routes.terms,
    routes.cookies,
    routes.team,
    routes.partners,
    routes.contact,
];

export default routes;
