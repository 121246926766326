/* eslint-disable react/no-array-index-key */
import styles from './Partners.module.scss';
import partners from './images';

const Team = () => {
    return (
        <div className={styles.pageContainer}>
            <div className={styles.worm1Mobile} />
            <div className={styles.worm2Mobile} />
            <div className={styles.worm3Mobile} />
            <div className={styles.splashDesktop} />
            <div className={styles.titleWrapper}>
                <div className={styles.wormDesktop} />
                <h1 className={styles.pageTitle}>
                    Partners and <span>Collaborators</span>
                </h1>
            </div>
            <div className={styles.partnersContainer}>
                {partners.map((partner, index) => (
                    <img key={index} src={partner} alt="" />
                ))}
            </div>
        </div>
    );
};

export default Team;
