import marcImage from '../../../assets/images/team-marc.png';
import paoloImage from '../../../assets/images/team-paolo.png';
import danielImage from '../../../assets/images/team-daniel.png';
import joseLuisImage from '../../../assets/images/team-jose-luis.png';
import styles from './Team.module.scss';
import Member from './member/Member';

const marc = {
    name: 'Marc Parellada',
    position: 'CMO',
    linkedin: 'https://www.linkedin.com/in/parelladamarc/',
    telegram: 'https://t.me/kubemarc',
    twitter: 'https://twitter.com/kubemarc',
    image: marcImage,
};

const paolo = {
    name: 'Paolo Della Pepa',
    position: 'CEO',
    linkedin: 'https://www.linkedin.com/in/paolodellapepa/',
    telegram: 'https://t.me/paolodpwm',
    twitter: 'https://twitter.com/paolodpwm',
    image: paoloImage,
};

const joseLuis = {
    name: 'Jose Luís',
    position: 'CTO',
    linkedin: 'https://www.linkedin.com/in/jgonzalez89/',
    telegram: 'https://t.me/Jgonzalez_89',
    twitter: 'https://twitter.com/JLgonzalez_89',
    image: joseLuisImage,
};

const Team = () => (
    <div className={styles.pageContainer}>
        <div className={styles.worm1Mobile} />
        <div className={styles.worm2Mobile} />
        <div className={styles.worm3Mobile} />
        <div className={styles.contentWrapper}>
            <div className={styles.splashDesktop} />
            <div className={styles.wormDesktop} />
            <h1 className={styles.pageTitle}>
                We are <span>KubeCoin</span>
            </h1>
            <div className={styles.teamContainer}>
                <div className={styles.teamWrapper}>
                    <div className={`${styles.teamMember} ${styles.paolo}`}>
                        <Member member={paolo} />
                    </div>
                    <div className={`${styles.teamMember} ${styles.marc}`}>
                        <Member member={marc} reverseInMobile />
                    </div>
                    <div className={`${styles.teamMember} ${styles.joseLuis}`}>
                        <Member member={joseLuis} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Team;
